import React from 'react';

export default function CopyRight() {
  return (
    <div className="fixed inset-x-0 bottom-0 flex w-full items-center justify-center bg-dark-lighter py-2 dark:bg-dark-700">
      <p className="text-sm text-primary dark:text-primary-light">
        &copy; <span>{new Date().getFullYear()}</span> Canvaza - All Rights
        Reserved.
      </p>
    </div>
  );
}
