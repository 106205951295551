'use client';

import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import React, { useMemo } from 'react';

import CopyRight from '@/common/component/layout/CopyRight';
import AuthRightContent from '@/common/component/layout/external/auth/AuthRightContent';
import Header from '@/common/component/layout/external/header';
import {
  CHECK_VERIFY_EMAIL,
  USER_INVITATION,
} from '@/shared/config/route-links.config';

import type { LayoutProps } from '../types';

export default function Layout({ children }: LayoutProps) {
  const pathname = usePathname();

  const isExcludedRightContent = useMemo(() => {
    const excludeLayoutPages = [CHECK_VERIFY_EMAIL.url, USER_INVITATION.url];
    return (
      pathname && excludeLayoutPages.some((path) => pathname.startsWith(path))
    );
  }, []);

  return (
    <div className="h-screen w-full">
      <Header />
      <div className="my-0 flex h-full w-full flex-col justify-center gap-6 overflow-y-scroll bg-dark-lighter px-3 py-20 xs:py-10 sm:px-12 md:my-12 md:py-28 xl:my-0 xl:px-0">
        <div className="flex w-full max-w-7xl flex-col items-center justify-center gap-20 pt-36 xs:pt-6 sm:mx-auto sm:flex-row sm:items-start">
          <div
            className={clsx(
              'flex h-full w-full max-w-7xl rounded-md sm:px-0 lg:pt-0',
              {
                'xl:w-3/5 2xl:w-3/5': !isExcludedRightContent,
                'justify-center': isExcludedRightContent,
              }
            )}
          >
            {children}
          </div>
          {!isExcludedRightContent && <AuthRightContent />}
        </div>
        <div className="py-2">
          <CopyRight />
        </div>
      </div>
    </div>
  );
}
