import React from 'react';

export default function AuthRightContent() {
  return (
    <div className="mt-0 hidden w-full flex-col items-start justify-start space-y-6 pt-24 md:flex md:w-11/12 md:pt-12 lg:mt-20 lg:items-start lg:pt-0 2xl:w-5/6">
      <p className="text-left text-xl font-extralight text-dark-600 xl:text-2xl">
        The Simplest way to <span className="pr-2 font-bold">record</span>
        and <span className="font-bold">share</span> video
      </p>
      <img
        src={'/images/sideImage.png'}
        alt="login image of laptop and mobile"
      />
    </div>
  );
}
